<template>
    <div>
        <div
            v-if="showPicture && assetPictureUrl"
            class="detail-image"
            :style="{ backgroundImage: `url(${assetPictureUrl})` }"
            @click="showImageInPopUp = true"
        />

        <pop-over
            v-show="showImageInPopUp"
            style="z-index: 300"
            @exit="showImageInPopUp = false"
        >
            <img :src="assetPictureUrl" class="modal-asset-picture" />
        </pop-over>

        <div class="l-inline l-gap-2 l-padded">
            <asset-avatar :tracker="tracker" :size="64" />

            <div class="l-stack l-gap-1 l-full-width">
                <div class="l-inline l-center-v l-gap-2">
                    <h3>{{ tracker.asset_details.name }}</h3>

                    <router-link
                        v-if="showEditButton && hasWritePermission"
                        :to="`/map/assets/${tracker.id}/edit`"
                    >
                        <IconButton>
                            <PencilIcon />
                        </IconButton>
                    </router-link>
                </div>

                <div class="l-inline l-gap-1">
                    <div v-if="network_rssi && network_snr">
                        <signal-strength-indicator
                            :network-rssi="network_rssi"
                            :network-snr="network_snr"
                        />
                    </div>

                    <div
                        v-if="batteryStatus"
                        class="l-inline l-center-v l-gap-1"
                    >
                        <BatteryIcon
                            width="24"
                            height="24"
                            :battery-level-in-percent="batteryStatus.value"
                            :warn="batteryStatus.low"
                        />

                        <p>{{ batteryStatus.value }} %</p>
                    </div>
                </div>

                <div
                    v-if="
                        tracker.asset_details &&
                            tracker.asset_details.identification
                    "
                    class="detail-line"
                >
                    <p>
                        {{ $t('identification') }}:
                        {{ tracker.asset_details.identification }}
                    </p>
                </div>

                <div v-if="batteryStatus" class="detail-line">
                    <p>{{ $t('lastBatteryMeasurement') }}:</p>

                    <TimeAgo :from-datetime="batteryStatus.last_update" />
                </div>

                <div
                    v-if="
                        tracker.last_gps_measurement &&
                            tracker.last_gps_measurement.timestamp
                    "
                    class="detail-line"
                >
                    <p>{{ $t('lastGPSMeasurement') }}:</p>

                    <TimeAgo
                        :from-datetime="tracker.last_gps_measurement.timestamp"
                    />

                    <IconButton @click="showDateInfoBox = !showDateInfoBox">
                        <InfoIcon :width="13" :height="13" />
                    </IconButton>
                </div>

                <transition name="dropdown">
                    <InfoBox v-if="showDateInfoBox">
                        {{ $t('datetimeHelp') }}
                    </InfoBox>
                </transition>

                <div
                    v-if="
                        tracker.last_gps_measurement &&
                            tracker.last_gps_measurement.gps_accuracy != null
                    "
                    class="detail-line"
                >
                    <p class="sensor-data-distance">
                        {{ $t('gpsAccuracy') }}:
                        {{ tracker.last_gps_measurement.gps_accuracy }}
                    </p>
                </div>

                <div v-if="tracker.last_message_timestamp" class="detail-line">
                    <p>{{ $t('lastContact') }}:</p>

                    <TimeAgo :from-datetime="tracker.last_message_timestamp" />
                </div>

                <div v-if="hasDebugAccess">
                    <p
                        class="t-underline t-subtle"
                        style="cursor: pointer"
                        @click="
                            showAdvancedConnectionInfo = !showAdvancedConnectionInfo
                        "
                    >
                        {{ $t('showadvancedinformation') }}
                    </p>
                </div>

                <transition name="dropdown">
                    <div
                        v-if="hasDebugAccess"
                        v-show="showAdvancedConnectionInfo"
                        class="l-stack l-gap-1"
                    >
                        <p v-show="deveui" class="l-inline">
                            DevEUI: {{ deveui }}
                        </p>
                        <p v-show="network_sf" class="l-inline">
                            SF: {{ network_sf }}
                        </p>
                        <p v-show="network_rssi" class="l-inline">
                            RSSI: {{ network_rssi }}
                        </p>
                        <p v-show="network_snr" class="l-inline">
                            SNR: {{ network_snr }}
                        </p>
                        <p v-show="network_name" class="l-inline">
                            {{ $t('network') }}: {{ network_name }}
                        </p>
                    </div>
                </transition>

                <div
                    v-if="
                        tracker.asset_details.tags &&
                            tracker.asset_details.tags.length
                    "
                    class="l-inline tags"
                >
                    <div
                        v-for="(tag, index) in tracker.asset_details.tags"
                        :key="index"
                        :class="tag.className"
                    >
                        {{ $t(tag.label) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AssetHelpers from '@/mixins/AssetHelpers'

import BatteryIcon from './icons/BatteryIcon'
import IconButton from './IconButton'
import InfoBox from './InfoBox'
import InfoIcon from './icons/InfoIcon'
import PencilIcon from './icons/PencilIcon'
import SignalStrengthIndicator from './SignalStrengthIndicator'
import TimeAgo from './TimeAgo'
import AssetAvatar from '@/components/AssetAvatar'
import PopOver from '@/components/PopOver'

export default {
    name: 'AssetDetailOverview',
    components: {
        PopOver,
        AssetAvatar,
        BatteryIcon,
        IconButton,
        InfoBox,
        InfoIcon,
        PencilIcon,
        SignalStrengthIndicator,
        TimeAgo,
    },
    mixins: [AssetHelpers],
    props: {
        tracker: {
            type: Object,
            required: true,
        },
        showPicture: {
            type: Boolean,
            default: true,
        },
        showEditButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showAdvancedConnectionInfo: false,
            showDateInfoBox: false,
            showImageInPopUp: false,
        }
    },
    computed: {
        ...mapGetters('authentication', ['hasDebugAccess']),
        assetPictureUrl() {
            return (
                this.tracker.asset_details.external_picture ||
                this.tracker.asset_details.picture ||
                this.trackerAssetTypePicture(
                    this.tracker.asset_details.asset_type_identifier
                )
            )
        },
        batteryStatus() {
            return this.tracker.asset_details.sensor_data?.battery
        },
        hasWritePermission() {
            return this.tracker.asset_details.permissions.access === 'WRITE'
        },
        deveui() {
            return this.tracker.deveui
        },
        network_sf() {
            return this.tracker.last_measurement?.network_data?.sf
        },
        network_rssi() {
            return this.tracker.last_measurement?.network_data?.rssi
        },
        network_name() {
            return this.tracker.last_measurement?.network_data?.network
        },
        network_snr() {
            return this.tracker.last_measurement?.network_data?.snr
        },
    },
}
</script>

<i18n>
{
    "en": {
        "beaconPosition": "Beacon Position",
        "datetimeHelp": "Trackers do not transmit their position with every message. The latest point of contact with a tracker could have therefore occurred after the last received geographic location.",
        "gpsAccuracy": "GPS accuracy",
        "identification": "Identification",
        "inactive": "INACTIVE",
        "lastBatteryMeasurement": "Last battery measurement",
        "lastContact": "Last contact",
        "lastGPSMeasurement": "Last GPS measurement",
        "leftZone": "LEFT ZONE",
        "showadvancedinformation": "show advanced information",
        "network": "Network"
    },
    "de": {
        "beaconPosition": "Beacon Position",
        "datetimeHelp": "Der Tracker schickt nicht in jeder Nachricht seinen Standort. Daraus folgt, dass der letzte Kontakt mit dem Tracker später sein kann als die letzte Standortmessung.",
        "gpsAccuracy": "GPS Genauigkeit",
        "identification": "Identifikation",
        "inactive": "INAKTIV",
        "lastBatteryMeasurement": "Letzte Akku Messung",
        "lastContact": "Letzter Kontakt",
        "lastGPSMeasurement": "Letzte GPS Messung",
        "leftZone": "ZONE VERLASSEN",
        "showadvancedinformation": "Erweiterte Informationen anzeigen",
        "network": "Netzwerk"
    },
    "it": {
        "beaconPosition": "Beacon Posizione",
        "datetimeHelp": "Il tracker non invia la sua posizione in ogni messaggio. Ciò significa che l'ultimo contatto con l'inseguitore può essere successivo all'ultima misurazione della posizione.",
        "gpsAccuracy": "GPS precisione",
        "identification": "Identificazione",
        "inactive": "INATTIVO",
        "lastBatteryMeasurement": "Ultimo Misurazione della Batteria",
        "lastContact": "Ultimo Contatto",
        "lastGPSMeasurement": "Ultimo Misurazione del GPS",
        "leftZone": "ESCONO ZONA",
        "showadvancedinformation": "mostra informazioni avanzate",
        "network": "Rete"
    }
}
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}

.detail-line {
    font-size: $fs-small;

    p {
        display: inline;
        margin-right: 0.3em;
    }

    button {
        margin-left: 0.3em;
        vertical-align: bottom;
    }
}

.detail-image {
    width: 100%;
    height: 200px;
    background-color: $color-gray-lighter;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    cursor: pointer;
}

.tags {
    flex-wrap: wrap;

    & > div {
        margin: 0 4px 4px 0;
        white-space: nowrap;
    }
}

.dropdown {
    &-enter,
    &-leave-to {
        max-height: 0;
        opacity: 0;
    }

    &-leave,
    &-enter-to {
        max-height: 400px;
        opacity: 1;
    }

    &-enter-active,
    &-leave-active {
        transition: max-height 0.2s linear, opacity 0.4s ease-in-out;
    }
}

.modal-asset-picture {
    max-width: 95vw;
    max-height: 95vh;
}
</style>
