<template>
    <div
        class="asset-avatar"
        :class="{ clickable: isLinkable }"
        :style="{ width: size + 'px', height: size + 'px' }"
        @click="handleClick"
    >
        <img
            :src="tracker.asset_details.asset_type_marker"
            :style="{
                border,
                borderColor: tracker.asset_details.color,
                boxShadow,
            }"
        />

        <div v-if="showErrorIcon" class="asset-avatar__icon">
            <error-icon
                v-if="showErrorIcon === 'error-generic'"
                :width="iconSize"
                :height="iconSize"
            />
            <battery-icon
                v-else-if="showErrorIcon === 'error-battery-low'"
                :width="iconSize"
                :height="iconSize"
            />
            <warning-icon
                v-else-if="showErrorIcon === 'warning-generic'"
                :width="iconSize"
                :height="iconSize"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import BatteryIcon from '@/components/icons/BatteryIcon'
import ErrorIcon from '@/components/icons/ErrorIcon'
import WarningIcon from '@/components/icons/WarningIcon'
import AssetHelpers from '@/mixins/AssetHelpers'

export default {
    name: 'AssetAvatar',
    components: {
        BatteryIcon,
        ErrorIcon,
        WarningIcon,
    },
    mixins: [AssetHelpers],
    props: {
        tracker: {
            type: Object,
            required: true,
        },
        size: {
            type: Number,
            default: 64,
        },
        linkable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showErrorIcon() {
            return this.getError(this.tracker)
        },
        border() {
            const borderSize = Math.round(this.size * 0.045)
            return `${borderSize}px solid ${this.tracker.asset_details.color}`
        },
        boxShadow() {
            const offset = Math.round(this.size * 0.08)
            return `0px ${offset}px 5px 0px rgba(0, 0, 0, 0.4)`
        },
        iconSize() {
            return Math.round(this.size * 0.3)
        },
        isLinkable() {
            return this.linkable || this.$route.name === 'detail'
        },
    },
    methods: {
        ...mapActions('map', ['centerMapOnTracker']),
        ...mapMutations('tracker', ['setShouldFollowActiveTrackerUpdates']),
        handleClick() {
            if (this.isLinkable) {
                if (this.$route.name === 'detail') {
                    this.centerMapOnTracker(this.tracker.id)
                    this.setShouldFollowActiveTrackerUpdates(true)
                } else {
                    this.$router.push({
                        name: 'detail',
                        params: {
                            id: this.tracker.id,
                        },
                    })
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.asset-avatar {
    position: relative;
    flex-shrink: 0;

    img {
        padding: 12.5%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #fff;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -8%;
        top: -8%;
        width: 50%;
        height: 50%;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    }
}
</style>
